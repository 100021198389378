import { Component, OnInit, ChangeDetectionStrategy, ElementRef, ViewEncapsulation } from '@angular/core';
import { LazyLoaderService } from '../lazy-loader.service';


@Component({
  selector: 'polylearn-angular-js',
  templateUrl: './angular-js.component.html',
  styleUrls: ['./angular-js.component.scss', '../../ng1/styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AngularJsComponent implements OnInit {

  constructor(
    private lazyLoader: LazyLoaderService,
    private elRef: ElementRef
  ) {}

  ngOnInit() {
    this.lazyLoader.load(this.elRef.nativeElement);
  }


  ngOnDestroy() {
    this.lazyLoader.destroy();
  }

}

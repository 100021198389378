import { Injectable } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import {setUpLocationSync} from '@angular/router/upgrade';


/* Trying to lazy load the import
import  "../ng1/www/vendor/angular/angular.js";
import  "../ng1/www/vendor/angular-animate/angular-animate.js";
import  "../ng1/www/vendor/angular-aria/angular-aria.js";
import  "../ng1/www/vendor/Chart.js/Chart.js";

import "angular-chart.js";
// import  "../ng1/www/vendor/angular-chart.js/dist/angular-chart.js";
import  "../ng1/www/vendor/angular-filter/dist/angular-filter.js";
import  "../ng1/www/vendor/marked/lib/marked.js";
import  "../ng1/www/vendor/angular-marked/angular-marked.js";
import  "../ng1/www/vendor/angular-messages/angular-messages.js";
import  "../ng1/www/vendor/angular-password/angular-password.js";
import  "../ng1/www/vendor/angular-resource/angular-resource.js";
import  "../ng1/www/vendor/angular-route/angular-route.js";
import  "../ng1/www/vendor/angular-sanitize/angular-sanitize.js";
import  "../ng1/www/vendor/angular-scroll/angular-scroll.js";
import  "../ng1/www/vendor/hello/dist/hello.all.min.js";
import  "../ng1/www/vendor/angularjs-socialshare/dist/angular-socialshare.min.js";
import  "../ng1/www/vendor/underscore/underscore.js";
import  "../ng1/www/vendor/angular-bind-html-compile/angular-bind-html-compile.js";
import  "../ng1/www/vendor/pusher-websocket-iso/dist/web/pusher.js";
import  "../ng1/www/vendor/scrollreveal/dist/scrollreveal.js";
import  "angular-material/angular-material.js";
import  "chartist/dist/chartist.js";
import  "../ng1/www/vendor/md-steppers/dist/md-steppers.js";
import  "../ng1/www/vendor/angular-update-meta/dist/update-meta.js";
import  "../ng1/www/vendor/angular-google-analytics/dist/angular-google-analytics.min.js";

// Already include in angular.json, problem of loading
// import  "../ng1/www/vendor/papaparse/papaparse.js";
import  "../ng1/www/vendor/fuse.js/dist/fuse.js";
import  "../ng1/www/vendor/moment/moment.js";
import  "../ng1/www/vendor/momentjs/locale/fr.js";
import  "../ng1/www/vendor/angular-moment/angular-moment.js";
import  "../ng1/www/cf_vendor/dropin.min.js";
import  "../ng1/www/cf_vendor/modernizr-custom.js";
import  "../ng1/www/cf_vendor/angular-sound-manager.js";
*/

declare var angular: any;
@Injectable({
  providedIn: 'root'
})
export class LazyLoaderService {
  private app: any; // angular.auto.IInjectorService;

  constructor(
    private upgrade: UpgradeModule
  ) {}

  load(el: HTMLElement): void {
    console.log('🔥');
    // this.app = angular.bootstrap(el,  ['PlatformExam'], { strictDi: true });



    import('../ng1/www/js/init').then(app => {
      try {
        console.log('🔥', app);
        this.app = this.upgrade.bootstrap(el, ['PlatformExam'], { strictDi: true });
        setTimeout(() => {
          setUpLocationSync(this.upgrade);
        }, 0);
        /*
        */
      } catch (e) {
        console.log('🔥🔥', app);

        console.error(e);
      }
    });


  }

  destroy() {
    if (this.app) {
      this.app.get('$rootScope').$destroy();
    }
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  private _getCookieData(key: string) {
    let apiData = window.localStorage.getItem('api');
    if (apiData?.length) {
      let data = JSON.parse(apiData);
      return (data[key]) ? data[key] : null;
    }
    return null;
  }

  getMeData() {
    let data = this._getCookieData('me');
    return data;
  }

  getMyToken() {
    let data = this._getCookieData('token');
    return data;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, UrlSegment, PreloadAllModules } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { AngularJsComponent } from './angular-js/angular-js.component';

import { IsLoggedGuard } from './shared/is-logged.guard';
import { IsSuperAdminGuard } from './shared/is-super-admin.guard';


export function isAngularJSUrl(url: UrlSegment[]) {
  console.log('url', url);

  return (url.length > 0 && url[0].path.startsWith('activation'))  ? ({consumed: url}) : ({consumed: url});
}

const routes: Routes = [
  // { path: 'blog', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule) },
  // { path: 'admin', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [/*IsLoggedGuard, IsSuperAdminGuard*/]
  },
  { matcher: isAngularJSUrl, component: AngularJsComponent },
];


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(
      routes, {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        enableTracing: true,
//        preloadingStrategy: PreloadAllModules // ADD THIS!
      },
    ),
    CommonModule,
  ],
})
export class AppRoutingModule { }

export const environment = {
  production: true,

  API_URL: 'https://api.polylearn.co',
  EXAM: 'toeic',
  ENVIRONMENT: {
    graphqlUrl:'https://graphql.polylearn.co/v1/graphql',
    nodeUrl:'https://node.api.polylearn.co/api/'
  },
  firebase: {
    apiKey: "AIzaSyC4pVCL5doRv5U_1o05Fmr1Kywowqx2NOA",
    authDomain: "toeic-new-design.firebaseapp.com",
    databaseURL: "https://toeic-new-design.firebaseio.com",
    projectId: "toeic-new-design",
    storageBucket: "toeic-new-design.appspot.com",
    messagingSenderId: "760238145503",
    appId: "1:760238145503:web:ca9138c1b7302e12424e6a"
  }
};

<div id="AppCtrl" ng-controller="AppCtrl">
  <md-content id="scrollreveal-container" layout="column"  class="grey-bg">

      <header class="app">
        <div ng-if="!main.me.roles" ng-include="'/ng1/www/modules/global/navbar/html/not_logged.html'"></div>
        <div ng-if="main.me.roles" ng-include="'/ng1/www/modules/global/navbar/html/logged.html'"></div>
      </header>

      <!--


      -->
      <div class="m-app-loading" ng-animate-children>

        <style type="text/css">
        div.m-app-loading {
          position: relative;
          /*fixed*/
        }

        div.m-app-loading div.animated-container {
          background-color: #fff;
          bottom: 0px;
          left: 0px;
          opacity: 1.0;
          position: fixed;
          right: 0px;
          top: 0px;
          z-index: 999999;
        }
        /* Used to initialize the ng-leave animation state. */

        div.m-app-loading div.animated-container.ng-animate {
          transition: all linear 20000ms;
          -webkit-transition: all linear 20000ms;
        }

        div.m-app-loading div.animated-container.ng-leave {
          opacity: 0;
        }
        /* Used to set the end properties of the ng-leave animation state. */

        div.m-app-loading div.animated-container.ng-leave-active {
          opacity: 0;
        }

        div.m-app-loading div.messaging {
          color: #000;
          font-family: monospace;
          left: 0px;
          margin-top: -37px;
          position: absolute;
          right: 0px;
          text-align: center;
          top: 30%;
        }

        div.m-app-loading h1 {
          font-size: 26px;
          line-height: 35px;
          margin: 0px 0px 20px 0px;
        }

        div.m-app-loading p {
          font-size: 18px;
          line-height: 14px;
          margin: 10px 0px 0px 0px;
        }

        .logo-container img {
          max-width: 100%;
        }

        </style>


        <!-- BEGIN: Actual animated container. -->
        <div class="animated-container">

          <div class="messaging">
            <div class="logo-container">
              <img src="/ng1/www/img/logo-nathan.png" height="70">
            </div>

            <p>
              Chargement
            </p>

          </div>

        </div>
        <!-- END: Actual animated container. -->

      </div>


      <section id="main-container" class="grey-bg" ng-view>
      </section>
  </md-content>
</div>
